@font-face {
  font-family: "Lato";
  src: url(./assets/fonts/Lato-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/fonts/OpenSans-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/fonts/OpenSans-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gibson SemiBold";
  src: local("Gibson SemiBold"),
    url(./assets/fonts/Gibson-SemiBold.otf) format("truetype");
}

@font-face {
  font-family: "Gibson Regular";
  src: local("Gibson Regular"),
    url(./assets/fonts/Gibson-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Gibson Light";
  src: local("Gibson Light"),
    url(./assets/fonts/Gibson-Light.ttf) format("truetype");
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Open Sans", "Arial", sans-serif;
}

a {
  text-decoration: none;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  white-space: pre-line;
}
